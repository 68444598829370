import { createAction, props } from '@ngrx/store';
import { IUser } from '@core/models/interfaces/user.interface';
import { ErrorCodeEnum } from '@core/enums/error-code.enum';

export const setInitData = createAction(
  '[Core] Set init data',
  props<{ language: string, idToken?: string, accessToken?: string, refreshToken?: string }>()
);

export const reloadApplication = createAction(
  '[Core] Reload application'
);

export const authorize = createAction(
  '[Core] Authorize'
);

export const checkToken = createAction(
  '[Core] Check token'
);

export const redirectToLogin = createAction(
  '[Core] Redirect to login'
);

export const redirectToErrorPage = createAction(
  '[Core] Redirect to error page',
  props<{ errorCode: ErrorCodeEnum }>()
);

export const redirectToDashboard = createAction(
  '[Core] Redirect to dashboard'
);

export const generateToken = createAction(
  '[Core] Generate token',
  props<{ code: string }>()
);

export const setToken = createAction(
  '[Core] Set token',
  props<{ idToken: string, accessToken: string, refreshToken: string }>()
);

export const updateToken = createAction(
  '[Core] Update token',
  props<{ idToken: string, accessToken: string, refreshToken: string }>()
);

export const setTokenFailed = createAction(
  '[Core] Set token failed'
);

export const getUser = createAction(
  '[Core] Get user'
);

export const setUser = createAction(
  '[Core] Set user',
  props<{ user: IUser }>()
);

export const logout = createAction(
  '[Core] Logout'
);

export const logoutSuccess = createAction(
  '[Core] Logout success'
);

