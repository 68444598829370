import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CORE_STATE_KEY, CoreState } from '@core/store/core.reducer';

const state = createFeatureSelector<CoreState>(CORE_STATE_KEY);

export const isAppLoaded = createSelector(
  state,
  ({ isAppLoaded }) => isAppLoaded
);

export const isRetrieveUserCompleted = createSelector(
  state,
  ({ retrieveUserCompleted }) => retrieveUserCompleted
);

export const selectErrorCode = createSelector(
  state,
  ({ errorCode }) => errorCode
);

export const selectUser = createSelector(
  state,
  state => state.user
);

export const selectUserId = createSelector(
  selectUser,
  user => user?.id
);

export const selectUserEmail = createSelector(
  selectUser,
  user => user?.email
);

export const selectTokenData = createSelector(
  state,
  ({ idToken, accessToken, refreshToken }) => ({ idToken, accessToken, refreshToken })
);

export const selectUserPermissions = createSelector(
  selectUser,
  ({ permission }) => permission
);

export const selectSessionData = createSelector(
  selectUserEmail,
  selectTokenData,
  (email, { idToken, accessToken, refreshToken }) => ({ email, idToken, accessToken, refreshToken })
);


